import { validator } from "@/utils/validator";

export const companySpecificBusinessClassificationSchema = validator.object().shape({
  companySpecificBusinessClassificationName: validator.string().emptyToNull().label("業務区分（会社情報用）").required(),
});

export const masterDataCreateCompanySpecificIndustrySchema = validator.object().shape({
  companySpecificIndustry: validator.string().emptyToNull().label("特定産業分野（企業）").required(),
  companySpecificIndustryVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード").required(),
  companySpecificBusinessClassifications: validator
    .array()
    .of(companySpecificBusinessClassificationSchema)
    .label("companySpecificBusinessClassifications")
    .required(),
});

export const masterDataUpdateCompanySpecificIndustrySchema = validator.object().shape({
  companySpecificIndustry: validator.string().emptyToNull().label("特定産業分野（企業）").required(),
  companySpecificIndustryVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード").required(),
});
