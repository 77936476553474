import { TCreateCompanyReqDto } from "@/api/services/admin/company/dtos/create-company.dto";
import { TGetCompanyDto } from "@/api/services/admin/company/dtos/get-company.dto";
import { TUpdateCompanyReqDto } from "@/api/services/admin/company/dtos/update-company.dto";
import { TGetUserDto } from "@/api/services/admin/company/user/dtos/get-user.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import { TCompanyForm } from "@/features/company/types/companyForm";
import { TCompanyUserForm } from "@/features/company/types/companyUserForm";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";

export const convertCompanyFormToCreateReq = (data: TCompanyForm): TCreateCompanyReqDto => {
  return {
    companyName: data.companyName,
    contractStatus: data.contractStatus,
    startReportQuarter: data.startReportQuarter,
    note: data.note ?? undefined,
    specificIndustryId: toNumber(data.specificIndustryId),
    companyDescription: data.companyDescription ?? undefined,
    contractStartDate: data.contractStartDate.format(ISO_FORMAT),
    contractEndDate: data.contractEndDate?.format(ISO_FORMAT) ?? null,
    startReportYear: data.startReportYear.get("year"),
    pricePerEmployee: data.pricePerEmployee ?? undefined,
  };
};

export const convertCompanyFormToUpdateReq = (data: TCompanyForm): TUpdateCompanyReqDto => {
  return {
    ...data,
    note: data.note ?? undefined,
    specificIndustryId: toNumber(data.specificIndustryId),
    companyDescription: data.companyDescription ?? undefined,
    contractStartDate: data.contractStartDate.format(ISO_FORMAT),
    contractEndDate: data.contractEndDate?.format(ISO_FORMAT) ?? null,
    startReportYear: data.startReportYear.get("year"),
    pricePerEmployee: data.pricePerEmployee ?? undefined,
  };
};

export const convertCompanyResToForm = (data: TGetCompanyDto): Partial<TCompanyForm> => {
  return {
    companyDescription: data.companyDescription ?? undefined,
    companyName: data.companyName,
    note: data.note ?? undefined,
    contractStatus: data.contractStatus,
    specificIndustryId: data.specificIndustryId.toString(),
    specificBusinessClassificationIds: data.specificBusinessClassificationIds,
    contractEndDate: data.contractEndDate ? dayjs(data.contractEndDate) : undefined,
    contractStartDate: dayjs(data.contractStartDate),
    startReportQuarter: data.startReportQuarter,
    startReportYear: dayjs().year(data.startReportYear),
    pricePerEmployee: data.pricePerEmployee ?? undefined,
  };
};

export const convertCompanyUserResToForm = (data: TGetUserDto): Partial<TCompanyUserForm> => {
  return {
    email: data.email,
    fullName: data.fullName,
    isCompanyUser: Number(data.isCompanyUser),
    activeStatus: data.activeStatus,
  };
};
