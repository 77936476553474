import { TGetConvertRequestListOutputDto } from "@/api/services/admin/convert-request/dtos/get-convert-request-list.dto";
import { useEnum } from "@/api/services/global/enum";
import { IconChevronRight } from "@/assets/icons";
import { IconBox } from "@/components/elements/IconBox";
import { Linkable } from "@/components/elements/Linkable";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { CONVERT_STATUS } from "@/constants/enum";
import { EMPTY_STR } from "@/constants/string";
import {
  isAnytimeEmploymentContractData,
  isAnytimeSupportContractData,
  isAnytimeSupportPlanData,
  isRetirementAcceptingWorkerData,
  isRetirementContractData,
} from "@/features/convert/utils/checkTypes";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

export type TConvertColumnProps = {
  hightLightOverdue?: boolean;
};

export const useConvertColumns = ({ hightLightOverdue }: TConvertColumnProps) => {
  const { data: convertStatusOptions } = useEnum("EConvertStatus");
  const { data: documentTypeOptions } = useEnum("EDocumentType");
  const { data: convertNameOptions } = useEnum("EConvertDocumentName");

  if (!convertStatusOptions || !documentTypeOptions) return;
  const columns = useMemo<MRT_ColumnDef<TGetConvertRequestListOutputDto>[]>(() => {
    return [
      {
        header: "業種",
        Cell: ({ row }) => row.original?.specificIndustry?.companySpecificIndustry || EMPTY_STR.TEXT,
        size: 80,
      },
      {
        header: "企業名",
        Cell: ({ row }) => row.original.companyName || EMPTY_STR.TEXT,
      },
      {
        header: "企業管理者",
        Cell: ({ row }) => row.original.representativeName || EMPTY_STR.TEXT,
      },
      {
        header: "申請カテゴリ",
        Cell: ({ row }) => {
          return findLabelByValue(documentTypeOptions, row.original.documentType) || EMPTY_STR.TEXT;
        },
        size: 100,
      },
      {
        header: "届出の名称",
        size: 100,
        Cell: ({ row }) => {
          try {
            if (row.original.json) {
              const { documentName, documentType } = row.original;
              const docJSON = JSON.parse(row.original.json);

              if (isAnytimeEmploymentContractData(docJSON)) return "雇用契約の変更に係る届出";
              if (isAnytimeSupportContractData(docJSON)) return "支援委託契約に係る届出";
              if (isAnytimeSupportPlanData(docJSON)) return "支援計画変更に係る届出";
              if (isRetirementAcceptingWorkerData(documentName, documentType, docJSON)) return "受入れ困難に係る届出";
              if (isRetirementContractData(documentName, documentType, docJSON)) return "特定技能雇用契約に係る届出";
            }
          } catch {
            // do nothing
          }
          return findLabelByValue(convertNameOptions, row.original.documentName) || EMPTY_STR.TEXT;
        },
      },
      {
        header: "ステータス",
        Cell: ({ row }) => {
          return findLabelByValue(convertStatusOptions, row.original.status) || EMPTY_STR.TEXT;
        },
        size: 80,
      },
      {
        header: "変換依頼日",
        Cell: ({ row }) => {
          const { createdAt } = row.original;
          const today = dayjs(new Date());
          const createdDate = dayjs(createdAt);
          if (row.original.status !== CONVERT_STATUS.COMPLETE && today.diff(createdDate, "days") >= 3 && hightLightOverdue) {
            return <Typography style={{ color: "#E73030" }}>{createdDate.format(DATE_TIME_FORMAT)}</Typography>;
          } else {
            return <Typography>{createdDate.format(DATE_TIME_FORMAT)}</Typography>;
          }
        },
        size: 100,
      },
      {
        header: "詳細",
        id: "action",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Linkable href={getDynamicRoute(AppRoutes.convertDetail, { id })}>
              <IconBox>
                <IconChevronRight />
              </IconBox>
            </Linkable>
          );
        },
        size: 20,
      },
    ];
  }, [convertStatusOptions, documentTypeOptions, convertNameOptions]);
  return columns;
};
