import { useCompanyList } from "@/api/services/admin/company";
import { TGetListCompanyDto } from "@/api/services/admin/company/dtos/get-company-list.dto";
import { useEnum } from "@/api/services/global/enum";
import { IconChevronRight } from "@/assets/icons";
import { IconBox } from "@/components/elements/IconBox";
import { Linkable } from "@/components/elements/Linkable";
import { Table, TableSorting } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { RETIREMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { formatNumber } from "@/utils/number";
import { findLabelByValue } from "@/utils/object";
import { truncateWithEllipsis } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const CompanyTable = () => {
  const { data: companyContractStatuses } = useEnum("ECompanyContractStatus");
  const navigate = useNavigate();
  const columns = useMemo<MRT_ColumnDef<TGetListCompanyDto>[]>(
    () => [
      {
        header: "企業名",
        id: "companyName",
        accessorKey: "companyName",
        Cell: ({ row }) => truncateWithEllipsis(row.original.companyName, 20),
        Header: <TableSorting name="company.companyName">企業名</TableSorting>,
      },
      {
        header: "企業ID",
        id: "companyCode",
        accessorKey: "companyCode",
        size: 100,
      },
      {
        header: "課金人数(1日)",
        size: 120,
        Cell: ({ row }) => {
          const totalFirstDayEnabledBillings = row.original.employeeBillings.filter((item) => item.isFirstDayPayment)?.length;
          return totalFirstDayEnabledBillings || 0;
        },
      },
      {
        header: "課金人数(現在)",
        Cell: ({ row }) => {
          const totalCurrentEnabledBillings = row.original.employeeBillings.filter(
            (item) =>
              item.isCurrentPayment &&
              item.employee?.retirementStatus !== RETIREMENT_STATUS.RETIRED &&
              [1, 2, 3].includes(Number(item.employee.residenceStatusId)),
          )?.length;
          return totalCurrentEnabledBillings || 0;
        },
        size: 40,
      },
      {
        header: "単価",
        Cell: ({ row }) => (row.original.pricePerEmployee ? `${formatNumber(row.original.pricePerEmployee)}円` : EMPTY_STR.TEXT),
        size: 20,
      },
      {
        header: "分野",
        Cell: ({ row }) => row.original?.specificIndustry?.companySpecificIndustry || EMPTY_STR.TEXT,
        Header: <TableSorting name="company.specificIndustry">分野</TableSorting>,
        size: 20,
      },
      {
        header: "ステータス",
        Cell: ({ row }) => findLabelByValue(companyContractStatuses, row.original.contractStatus) || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "契約開始日",
        Cell: ({ row }) => (row.original.contractStartDate ? dayjs(row.original.contractStartDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
        Header: <TableSorting name="company.contractStartDate">契約開始日</TableSorting>,
        size: 120,
      },
      {
        header: "管理者ユーザー",
        Cell: ({ row }) => row.original.users?.find((user) => user.isCompanyAdmin)?.fullName ?? EMPTY_STR.TEXT,
      },
      {
        header: "詳細",
        id: "action",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Linkable href={getDynamicRoute(AppRoutes.companyDetail, { id })}>
              <IconBox>
                <IconChevronRight />
              </IconBox>
            </Linkable>
          );
        },
        size: 20,
      },
    ],
    [companyContractStatuses],
  );

  const { data, isLoading } = useCompanyList();
  const { table } = useTable({
    columns,
    rows: data?.data ?? [],
    onClickRow: (row) => navigate(getDynamicRoute(AppRoutes.companyDetail, { id: row.id })),
    pinningColumns: ["action"],
  });

  return <Table table={table} isLoading={isLoading} />;
};
