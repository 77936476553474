import { TMasterCompanySpecificBusinessClassification } from "@/api/entities/master-company-specific-business-classification.entity";
import { updateCompanySpecificIndustryApi } from "@/api/services/global/master";
import { TGetCompanySpecificIndustryListOutputDto } from "@/api/services/global/master/dtos/companySpecificIndustry/get-master-company-specific-industry-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { UpdateCompanySpecificIndustryFormTable } from "@/features/master-data/components/CompanySpecificIndustry/UpdateCompanySpecificIndustryFormTable";
import { masterDataUpdateCompanySpecificIndustrySchema } from "@/features/master-data/schema/masterDataCompanySpecificIndustrySchema";
import { TMasterCompanySpecificIndustryUpdateForm } from "@/features/master-data/types/masterDataCompanySpecificIndustryForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";

type TUpdateCompanySpecificIndustryFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedCompanySpecificIndustry?: TGetCompanySpecificIndustryListOutputDto;
};

export const UpdateCompanySpecificIndustryFormModal: FC<TUpdateCompanySpecificIndustryFormModalProps> = ({
  onClose,
  isOpen,
  selectedCompanySpecificIndustry,
}) => {
  const queryClient = useQueryClient();

  const [selectedCompanySpecificBusinessClassificationRows, setSelectedCompanySpecificBusinessClassificationRows] = useState<
    TMasterCompanySpecificBusinessClassification[]
  >(selectedCompanySpecificIndustry?.companySpecificBusinessClassifications ?? []);

  const methods = useForm<TMasterCompanySpecificIndustryUpdateForm>({
    resolver: yupResolver(masterDataUpdateCompanySpecificIndustrySchema),
    mode: "onBlur",
    defaultValues: {
      companySpecificIndustry: selectedCompanySpecificIndustry?.companySpecificIndustry,
      companySpecificIndustryVisaCsvCode: selectedCompanySpecificIndustry?.companySpecificIndustryVisaCsvCode,
    },
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterCompanySpecificIndustryUpdateForm) => {
    try {
      const rs = await updateCompanySpecificIndustryApi({
        companySpecificIndustries: [
          {
            id: toNumber(selectedCompanySpecificIndustry?.id),
            companySpecificIndustry: data.companySpecificIndustry,
            companySpecificIndustryVisaCsvCode: data.companySpecificIndustryVisaCsvCode,
            companySpecificBusinessClassifications: selectedCompanySpecificBusinessClassificationRows.map((item) => ({
              id: toNumber(item.id),
              companySpecificBusinessClassificationName: item.companySpecificBusinessClassificationName,
            })),
          },
        ],
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_COMPANY_SPECIFIC_INDUSTRY] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal width={1200} title="追加" isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataUpdateCompanySpecificIndustrySchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField control={control} name="companySpecificIndustry">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="companySpecificIndustryVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="body14Bold">業務区分（会社情報用）</Typography>
              <UpdateCompanySpecificIndustryFormTable
                selectedCompanySpecificBusinessClassificationRows={selectedCompanySpecificBusinessClassificationRows}
                setSelectedCompanySpecificBusinessClassificationRows={setSelectedCompanySpecificBusinessClassificationRows}
                existingCompanySpecificIndustryId={toNumber(selectedCompanySpecificIndustry?.id)}
                existingCompanySpecificIndustry={selectedCompanySpecificIndustry?.companySpecificIndustry ?? EMPTY_STR.TEXT}
                existingCoCompanySpecificIndustryVisaCsvCode={selectedCompanySpecificIndustry?.companySpecificIndustryVisaCsvCode ?? EMPTY_STR.TEXT}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
