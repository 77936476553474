import { useMe } from "@/api/services/admin/auth";
import { InfiniteScroll } from "@/components/elements/InfiniteScroll";
import { EMPTY_STR } from "@/constants/string";
import { useChat } from "@/features/chat/providers/ChatProvider";
import { TRoom } from "@/features/chat/types/room";
import { AppRoutes } from "@/routes/config";
import { background, state, text } from "@/theme/colors";
import { textEllipsis } from "@/theme/minxin";
import { typography } from "@/theme/typography";
import { getDynamicRoute } from "@/utils/url";
import { Box, Stack, styled } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

export const RoomList = () => {
  const { roomList } = useChat();
  const { id } = useParams();

  const rooms = useMemo(() => [...roomList.newRooms, ...roomList.oldRooms], [roomList.newRooms, roomList.oldRooms]);

  return (
    <Stack paddingY={1} height="100%">
      <InfiniteScroll loadMore={roomList.fetchNextPage} hasMore={roomList.hasNextPage}>
        {rooms.map((room) => {
          return <RoomItem key={room.id} active={id === room.id.toString()} data={room} />;
        })}
      </InfiniteScroll>
    </Stack>
  );
};

type RoomItemProps = {
  data: TRoom;
  active: boolean;
};

const RoomItem: FC<RoomItemProps> = ({ active, data }) => {
  const { id } = useParams();
  const latestMessage = data.messages?.[0];
  const { data: meResult } = useMe();
  const [unread, setUnread] = useState(!!meResult && !latestMessage?.readUserIds.some((id) => id.toString() === meResult.data.id.toString()));

  useEffect(() => {
    setUnread(!!meResult && !(latestMessage?.readUserIds.some((id) => id.toString() === meResult.data.id.toString()) || id === data.id?.toString()));
  }, [data]);

  return (
    <RoomContainer
      className={active ? "active" : ""}
      component={Link}
      to={getDynamicRoute(AppRoutes.chatDetail, { id: data.id })}
      onClick={() => {
        if (unread) {
          setUnread(false);
        }
      }}
    >
      {unread && (
        <UnreadIndicator>
          <svg width="30" height="30" viewBox="0 0 24 24">
            <circle cx="18" cy="6" r="2.5" fill={state.error_1} stroke={state.error_1} />
          </svg>
        </UnreadIndicator>
      )}
      <RoomDetails>
        <RoomTitle>
          <p className="title">{data.creator?.fullName}</p>
        </RoomTitle>
        <UserEmail>{data.company?.companyName || EMPTY_STR.TEXT}</UserEmail>
        <RoomSubtitle>{data.messages?.[0].text || EMPTY_STR.TEXT}</RoomSubtitle>
      </RoomDetails>
    </RoomContainer>
  );
};

const RoomContainer = styled(Box)<{ to: string | number }>`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #e5e7eb;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &.active {
    background-color: ${background.disable};
    pointer-events: none;
  }

  &:not(.active):hover {
    background-color: ${background.primary};
  }
`;

const UnreadIndicator = styled(Box)`
  position: absolute;
  top: 6px;
  right: 6px;
  color: #ef4444;
  font-size: 10px;
`;

const RoomDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
`;

const RoomTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${typography.sub16Bold}
`;

const UserEmail = styled(Box)`
  font-weight: bold;
  gap: 4px;
  ${typography.cap12Bold}
  color: ${text.primary};
`;

const RoomSubtitle = styled(Box)`
  color: ${text.secondary};
  font-size: 16px;
  padding-top: 4px;
  ${typography.body14}
  ${textEllipsis}
`;
