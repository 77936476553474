import { API_UNKNOWN_ERROR_MESSAGE } from "@/constants/common";
import { ERROR_STATUS_CODES } from "@/constants/error";
import { AppRoutes } from "@/routes/config";
import { TApiError } from "@/types/api";
import { deepClone } from "@/utils/object";
import { toast } from "@/utils/toast";
import { getDynamicRoute, getParams } from "@/utils/url";
import { AxiosError } from "axios";
import Cookies from "js-cookie";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { ValidationError } from "yup";

/**
 * Type guard to determine if an error is of type TApiError.
 * @param error - The error to check.
 * @returns True if the error is a TApiError, otherwise false.
 */
export const isApiError = (error: unknown): error is TApiError => {
  return !!error && typeof error === "object" && "message" in error;
};

/**
 * Type guard to determine if an error is a Yup ValidationError.
 * @param error - The error to check.
 * @returns True if the error is a ValidationError, otherwise false.
 */
export const isValidatorError = (error: unknown): error is ValidationError => {
  return !!error && typeof error === "object" && "path" in error && "message" in error;
};

/**
 * Sets form errors based on the error type received from an API or Yup validation.
 * @param methods - React Hook Form methods for setting form errors.
 * @param error - The error to process.
 */
export const setErrorForm = <T extends FieldValues>(methods: UseFormReturn<T, object, undefined>, error: unknown) => {
  const { setError } = methods;

  if (isValidatorError(error)) {
    // Set Yup validation error
    setError(error.path as Path<T>, { message: error.message });
    return;
  }

  if (isApiError(error)) {
    // Set API error for each field if error contains an array of errors
    if (error.error && Array.isArray(error.error)) {
      error.error.forEach(({ property, message }) => {
        setError(property as Path<T>, { message });
      });
      return;
    }
  }
};

/**
 * Displays an error toast message based on the error type.
 * @param error - The error to display.
 */
export const showError = (error: unknown) => {
  if (isApiError(error) && error.message) {
    // Show API error message in toast
    toast.error(error.message);
  } else {
    // Show unknown error message in toast
    toast.error(API_UNKNOWN_ERROR_MESSAGE);
  }
};

/**
 * Handles API errors based on the error code and status.
 * Redirects users based on specific error codes.
 * @param error - The Axios error to handle.
 */
export const handleApiErrorWithCode = (error: AxiosError) => {
  if (error.code === "ERR_NETWORK") {
    // Handle network error by redirecting to server error page
    window.location.replace(AppRoutes.serverErrorPage);
    return;
  }

  if (error.config?.method === "get") {
    // Handle GET request errors
    if (isApiError(error.response?.data)) {
      const { code, message } = error.response.data;
      toast.error(message);

      switch (code) {
        case ERROR_STATUS_CODES.INFORMATION_NOT_EXISTS:
          window.location.replace(AppRoutes.information);
          return;
        case ERROR_STATUS_CODES.COMPANY_NOT_EXISTS:
          window.location.replace(AppRoutes.company);
          return;
        case ERROR_STATUS_CODES.USER_NOT_EXISTS:
          window.location.replace(getDynamicRoute(AppRoutes.companyDetail, getParams(AppRoutes.companyDetail, window.location.pathname)));
          return;
        case ERROR_STATUS_CODES.ADMIN_MEMBER_NOT_EXISTS:
          window.location.replace(AppRoutes.adminUser);
          return;
      }
    }
  }

  // Handle 404 errors for GET requests
  if (error.response?.status === 404 && error.config?.method === "get") {
    window.location.replace(AppRoutes.pageNotFound);
  }

  // Handle 500 errors for GET requests (commented out for future use)
  // if (error.response?.status === 500 && error.config?.method === "get") {
  //   window.location.replace(AppRoutes.serverErrorPage);
  // }
};

export const getCustomSentryExceptionError = (error: AxiosError) => {
  const serverErrorResponse = error.response?.data as { message: string; code: string; error: unknown };
  let name = `[Irohana Admin]`;
  console.log("customErrorResponse", serverErrorResponse);
  if (serverErrorResponse) {
    console.log("customErrorResponse1", serverErrorResponse);
    const customErrorResponseMessage = serverErrorResponse?.error
      ? typeof serverErrorResponse?.error === "string"
        ? serverErrorResponse?.error
        : JSON.stringify(serverErrorResponse?.error)
      : undefined;
    name = name + `: ${serverErrorResponse.message}`;
    const message = `${serverErrorResponse.code}\n${customErrorResponseMessage ?? ""}`;
    const user = { email: Cookies.get("email") };
    const sendError = new Error(`${name} \n ${message} \n ${JSON.stringify(user)}`);
    return sendError;
  } else {
    return new Error(`${name} \n ${error.message}`);
  }
};
